<template>
    <headers></headers>
    <div class="top-gap"></div>
    <div class="content">
        <div class="desc">
            <h3>SOLFI Pay</h3>
            <p>{{ $t('pay.desc') }}</p>
        </div> 
        <div class="pay-bg">
            <img src="../../static/images/pay-logo.png" alt="">
        </div>
        <div class="date">
            <div>
                <div class="num">{{ date.days }}</div>
                <div>{{ $t('pay.days') }}</div>
            </div>
            <div>
                <div class="num">{{ date.hours }}</div>
                <div>{{ $t('pay.hours') }}</div>
            </div>
            <div>
                <div class="num">{{ date.minutes }}</div>
                <div>{{ $t('pay.minutes') }}</div>
            </div>
            <div>
                <div class="num">{{ date.seconds }}</div>
                <div>{{ $t('pay.seconds') }}</div>
            </div>
        </div>
        <div class="buts">
            <button @click="getWallet()">
                <img src="../../static/images/android.png" alt="">
                <span>{{ $t('pay.android') }}</span>
                <div></div>
            </button>
            <button @click="getWallet()">
                <img src="../../static/images/ios.png" alt="">
                <span>{{ $t('pay.ios') }}</span>
                <div></div>
            </button>
        </div>
    </div>
    <bases></bases>
</template>
<script setup>
    import headers from '@/components/header'
    import bases from '@/components/base'
    import {computed, reactive, watch, onMounted, onUnmounted } from 'vue';
    import Util from "@/utils/common-util";

    import {call, getContractAddress, send} from "@/contract/web3-util";
    import Web3 from "web3";
    import {memberStore} from "@/store/member"
    import {walletStore} from "@/store/wallet"
    import {webStore} from "@/store/web"
    import i18n from "@/i18n";
    import {ElMessage} from "element-plus";
    import {useRouter} from "vue-router";

    const mStore = memberStore()
    const wStore = walletStore()
    const wbStore = webStore()

    const router = new useRouter()

    let timerId;

    onMounted(() => {
        timerId = setInterval(getTimeDifference, 1000);
    })

    onUnmounted(() => {
        clearInterval(timerId);
    });

    const getWallet = () => {
        ElMessage({
            message: i18n.global.t('alert.soon'),
            type: 'error'
        })
    }

    let date = reactive({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00'
    }) 
    function getTimeDifference() {
        const now = new Date();
        let timestamp = 1728604800000 // 2024-10-11 08:00
        const targetDate = new Date(timestamp);

        // 计算时间差，以毫秒为单位
        let difference = targetDate - now;

        if (difference < 0) {
            date.days = '00';
            date.hours = '00';
            date.minutes = '00';
            date.seconds = '00';
            return date;
        }

        // 计算天数
        date.days = Math.floor(difference / (1000 * 60 * 60 * 24));
        difference -= date.days * (1000 * 60 * 60 * 24);

        // 计算小时
        date.hours = Math.floor(difference / (1000 * 60 * 60));
        difference -= date.hours * (1000 * 60 * 60);

        // 计算分钟
        date.minutes = Math.floor(difference / (1000 * 60));
        difference -= date.minutes * (1000 * 60);

        // 计算秒数
        date.seconds = Math.floor(difference / 1000);

        // 将不足部分设置为"00"
        date.days = date.days > 0 ? date.days.toString().padStart(2, '0') : '00';
        date.hours = date.hours > 0 ? date.hours.toString().padStart(2, '0') : '00';
        date.minutes = date.minutes > 0 ? date.minutes.toString().padStart(2, '0') : '00';
        date.seconds = date.seconds > 0 ? date.seconds.toString().padStart(2, '0') : '00';
    }


    const curAccount = computed(()=>{
      return wStore.curAccount
    })
    watch(curAccount, (newVal, oldVal) =>{
      if (newVal) {
        if (oldVal) {
          alert(i18n.global.t('alert.reLogin'))
        }

        wbStore.accountLogin().then((result) => {
          wbStore.setLoginState(true)
          mStore.queryMemberInfo()

        }).catch((reason) => {
          wbStore.setLoginState(false)
          console.error('login failed')
        })
      }
    })
</script>
<style scoped src="./css/pay.css"></style>
